import { VideoModalStore } from '@/compnents/videoModal.tsx';
import type { SysEmployerInfoVO } from '@/pages/employer/employerManagement/type.ts';
import type { PlatformAccountStatisticsVO, SysDictVO } from '@/pages/finance/financeDetail/type.ts';
import {
  type BaseData,
  MainSubStructureModel,
  NormalProgramme,
  type PaginationData,
  exportXlsx,
  getPageId,
  request,
  transformKeys,
} from '@/utils';
import { UploadOutlined } from '@ant-design/icons';
import { Image, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable, runInAction, toJS } from 'mobx';

export class FinanceDetailStore {
  constructor() {
    this.getDetailType();
    this.getEmployerList();
    this.getServiceList();

    this.mainSubStructureModel.getFilterParams = () => this.filterSet.filterItems.params;
    this.filterSet.handleSearch();
  }

  public videoModalStore = new VideoModalStore();
  @observable public addModal = false;
  private exportParams = {};
  @observable public employerList: SysEmployerInfoVO[] = [];
  @observable public employerListParams: Record<string, any> = { pageNum: 1, pageSize: 10 }; // 查询雇主
  @observable public employerListMaxPageNum: number = null; // 雇主列表最大页
  @observable public serviceList: any[] = [];
  @observable public chooseDetailType: number = null;
  @observable public detailTypeOption = []; // 收支明细类型
  @observable public statistics: PlatformAccountStatisticsVO = null; // 统计数据

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增明细',
        permissionId: `${getPageId()}_80`,
        handleClick: () => {
          runInAction(() => {
            this.addModal = true;
          });
        },
      },
      {
        text: '导出',
        iconNode: <UploadOutlined />,
        handleClick: () => {
          exportXlsx('/pbb/platform/platform/account/record/export', this.exportParams, '资金明细');
        },
      },
    ],
    pageId: `${new URLSearchParams(window.location.search)?.get('pageId')}chooseUser`,
    grid: {
      rowHeight: 70,
      columns: [
        {
          key: 'source',
          name: '收支名称',
          formatter: ({ row }) => {
            return this.detailTypeOption.find((item) => Number(item.value) === Number(row.source))?.label;
          },
        },
        {
          key: 'type',
          name: '收支类型',
          formatter: ({ row }) => {
            return row.type === 1 ? '收入' : '支出';
          },
        },
        {
          key: 'amount',
          name: '金额',
          formatter: ({ row }) => {
            return row.type === 1 ? (
              <span className="text-[#F5222D]">+{row.amount}</span>
            ) : (
              <span className="text-[#49bc26]">-{row.amount}</span>
            );
          },
        },
        {
          key: 'detailContent',
          name: '明细内容',
        },
        {
          key: 'imageList',
          name: '收支凭证',
          formatter: ({ row }) => {
            return (
              <div className="flex flex-wrap h-full items-center">
                {row?.imageList?.map((item, i) => {
                  return (
                    <Image
                      className="w-6 !h-6 mr-1 object-cover"
                      src={item}
                      key={item + String(i)}
                    />
                  );
                })}
              </div>
            );
          },
        },
        {
          key: 'createType',
          name: '创建方式',
          formatter: ({ row }) => {
            return Number(row.createType) === 1 ? '系统' : '手动';
          },
        },
        {
          key: 'createName',
          name: '创建人',
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => {
            return row.createTime ? dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        if (filterParams?.createTime) {
          const [startTime, endTime] = filterParams.createTime.split(',');
          filterParams.startTime = `${dayjs(startTime).format('YYYY-MM-DD')} 00:00:00`;
          filterParams.endTime = `${dayjs(endTime).format('YYYY-MM-DD')} 23:59:59`;
        }

        const postParams = {
          ...filterParams,
          ...rest,
        };
        this.exportParams = postParams;
        return request<BaseData<any>>({
          url: '/pbb/platform/platform/account/record/list',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  });

  public filterSet: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        span: 4,
        field: 'source',
        label: '收支名称:',
        placeholder: '请选择',
      },
      {
        type: 'select',
        span: 4,
        field: 'type',
        label: '收支类型:',
        placeholder: '请选择',
        data: [
          {
            label: '收入',
            value: '1',
          },
          {
            label: '支出',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        span: 4,
        field: 'createType',
        label: '创建方式:',
        placeholder: '请选择',
        data: [
          {
            label: '系统',
            value: '1',
          },
          {
            label: '手动',
            value: '2',
          },
        ],
      },
      {
        type: 'dateRange',
        span: 6,
        field: 'createTime',
        label: '创建时间:',
      },
    ],
    handleSearch: () => {
      this.getStatistics();
      return this.mainSubStructureModel.onQuery();
    },
  });

  @action public onChangeModal = (key: string, visible: boolean) => {
    this[key] = visible;
  };

  @action public getDetailType = () => {
    request<BaseData<SysDictVO[]>>({
      url: '/pbb/platform/anon/system/sysDict/getListByType',
      method: 'POST',
      data: {
        dictType: 'platform_account_source',
      },
    }).then((res) => {
      const transformArr: any = transformKeys(res.data, { dictName: 'label', dictValue: 'value' });
      this.detailTypeOption = transformArr;
      this.filterSet.filterItems.addDict({ source: transformArr });
    });
  };

  @action public getStatistics = () => {
    const params = this.mainSubStructureModel.getFilterParams();
    request<BaseData<PlatformAccountStatisticsVO>>({
      url: '/pbb/platform/platform/account/record/statistics',
      method: 'POST',
      data: {
        ...params,
      },
    }).then((res) => {
      this.statistics = res.data;
    });
  };

  @action public getEmployerList = (params: any = {}) => {
    this.employerListParams = {
      pageNum: 1,
      pageSize: 200,
      name: params.name || undefined,
    };
    request<PaginationData<SysEmployerInfoVO>>({
      url: '/pbb/platform/sys/appletUser/list',
      method: 'POST',
      data: {
        ...this.employerListParams,
      },
    }).then((res) => {
      this.employerListMaxPageNum = Math.ceil(res.data.total / this.employerListParams.pageSize);
      this.employerList = transformKeys(res.data.list, {
        name: 'label',
        id: 'value',
      });
      console.log('this.employerList', toJS(this.employerList));
    });
  };

  @action public loadMoreEmployerList = () => {
    if (this.employerListParams.pageNum >= this.employerListMaxPageNum) {
      return;
    }
    this.employerListParams.pageNum++;
    request<PaginationData<SysEmployerInfoVO>>({
      url: '/pbb/platform/sys/appletUser/list',
      method: 'POST',
      data: {
        ...this.employerListParams,
      },
    }).then((res) => {
      const employerList = transformKeys(res.data.list, {
        name: 'label',
        id: 'value',
      });
      this.employerList = [...this.employerList, ...employerList];
    });
  };

  @action public getServiceList = () => {
    request<BaseData<any>>({
      url: '/pbb/platform/sys/service/package/simple/list',
      method: 'POST',
      data: {
        functionType: [7, 9, 12, 13, 14],
      },
    }).then((res) => {
      this.serviceList = transformKeys(res.data, {
        name: 'label',
        id: 'value',
      });
    });
  };

  public onAddItem = async (data: any) => {
    if (Number(data.amount) <= 0) {
      message.info('输入金额应大于0');
      return;
    }
    Modal.confirm({
      title: '提示',
      content: '请仔细核对，确认后不可修改',
      onOk: () => {
        const newData = { ...data, userId: data.userId.value };
        request({
          url: '/pbb/platform/platform/account/record/add',
          method: 'POST',
          data: {
            ...newData,
          },
        }).then(() => {
          message.success('提交成功');
          this.addModal = false;
          this.filterSet.handleSearch();
        });
      },
      onCancel: () => {
        console.log('取消');
      },
    });
  };

  @action public changeDetailType = (id) => {
    this.chooseDetailType = Number(id);
  };
}
